
(() => {
    'use strict'

    const WebPush = {
        init() {
            self.addEventListener('push', this.notificationPush.bind(this))
            self.addEventListener('notificationclick', this.notificationClick.bind(this))
            self.addEventListener('notificationclose', this.notificationClose.bind(this))
        },

        /**
         * Handle notification push event.
         *
         * https://developer.mozilla.org/en-US/docs/Web/Events/push
         *
         * @param {NotificationEvent} event
         */
        notificationPush(event) {
            if (!(self.Notification && self.Notification.permission === 'granted')) {
                return
            }

            // https://developer.mozilla.org/en-US/docs/Web/API/PushMessageData
            if (event.data) {
                event.waitUntil(
                    this.sendNotification(event.data.json())
                )
            }
        },

        /**
         * Handle notification click event.
         *
         * https://developer.mozilla.org/en-US/docs/Web/Events/notificationclick
         * 
         * https://developers.google.com/web/fundamentals/push-notifications/common-notification-patterns
         *
         * @param {NotificationEvent} event
         */
        notificationClick(event) {
            event.notification.close()
            var redirectTo = '/';

            if (event.notification.data?.url) {
                redirectTo = event.notification.data.url;
            }
            else if (event.action === 'buy') {
                redirectTo = 'https://www.figaro.co.jp/product/feature/falc11-21.html';
            }


            const urlToOpen = new URL(redirectTo, self.location.origin).href;
            const promiseChain = clients.matchAll({
                type: 'window',
                includeUncontrolled: true
            }).then((windowClients) => {
                let matchingClient = null;

                for (let i = 0; i < windowClients.length; i++) {
                    const windowClient = windowClients[i];
                    if (windowClient.url === urlToOpen) {
                        matchingClient = windowClient;
                        break;
                    }
                }

                if (matchingClient) {
                    return matchingClient.focus();
                } else {
                    return clients.openWindow(urlToOpen);
                }
            });

            event.waitUntil(promiseChain);

        },

        /**
         * Handle notification close event (Chrome 50+, Firefox 55+).
         *
         * https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerGlobalScope/onnotificationclose
         *
         * @param {NotificationEvent} event
         */
        notificationClose(event) {
            self.registration.pushManager.getSubscription().then(subscription => {
                if (subscription) {
                    this.dismissNotification(event, subscription)
                }
            })
        },

        /**
         * Send notification to the user.
         *
         * https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/showNotification
         *
         * @param {PushMessageData|Object} data
         */
        sendNotification(data) {
            try {
                return self.registration.showNotification(data.title, data)
            } catch (error) {
                console.error(error);
                var title = 'Test message';
                var body = event.data.text();
                var icon = '/images/smiley.svg';
                var tag = 'simple-push-example-tag';
                event.waitUntil(
                    self.registration.showNotification(title, {
                        body: body,
                        icon: icon,
                        tag: tag
                    })
                );
            }

        },

        /**
         * Send request to server to dismiss a notification.
         *
         * @param  {NotificationEvent} event
         * @param  {String} subscription.endpoint
         * @return {Response}
         */
        dismissNotification({ notification }, { endpoint }) {
            notification.close();
            // if (!notification.data || !notification.data.id) {
            //     return
            // }

            // const data = new FormData()
            // data.append('endpoint', endpoint)

            // // Send a request to the server to mark the notification as read.
            // fetch(`/notifications/${notification.data.id}/dismiss`, {
            //     method: 'POST',
            //     body: data
            // })
        }
    }

    WebPush.init()
})()